<template>
    <div>
        <div :class="{'disabled':disabled}" class="dropdown dropdownBottom w-100" ref="dropdownMenu" style="margin-top: 6px;">
            <a class="dropdown-toggle d-flex d-select" id="dropdownMenuMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <!-- <span class="optionsDisplay" v-if="selectedOption.length>0" :class="{'opacityKey':search.length>0}">{{selectedOption}}</span> -->
                <input v-if="!disabled" @input="showOptions" v-on="$listeners" v-validate="validateOptions.join('|')" :name="$attrs.name" v-on:keydown="clearFieldEvent" :placeholder="placeholder" type="text" :class="{'select-placeholder':selectedOption.length>0}" v-model="search" class="inputDropdown">
                <input v-else v-on:keydown="clearFieldEvent" v-validate="validateOptions.join('|')" :name="$attrs.name + index.toString()" :placeholder="placeholder" type="text" :class="{'select-placeholder':selectedOption.length>0,'not-allowed':disabled}" v-model="search" class="form-control" readonly>
                <div class="input-group-append">
                    <span class="clearSelect" @click="clearField()" v-if="search.length>0 && clearable">
                        <i class="icon icon-close font-weight-bold"></i>
                    </span>
                    <span class="toggleImage" @click="emitDropDownOpen()">
                        <i class="icon icon-menu-down" v-if="!optionsVisible"></i>
                        <i class="icon icon-menu-up" v-else></i>
                    </span>
                </div>
            </a>
            <div :id="id" v-if="disabled == false" class="dropdown-menu" v-show="optionsVisible" aria-labelledby="dropdownMenuMenu">
                <router-link v-if="to.length>0" class="dropdown-item text-secondary" :to="to">Add<span class="pl-1" v-if="search!=''">{{JSON.stringify(search)}}</span></router-link>
                <div v-if="filterOptions.length  == 0 && !loading">
                    <a class="dropdown-item text-center font-weight-bold" href="javascript:;">
                        <slot name="no-options">No Matching Found</slot>
                    </a>
                </div>
                <div v-else v-for="(options,index) in filterOptions" @click="selectValue(options)" :key="index">
                    <a class="dropdown-item dropdown-options"  v-if="isObjectOptions" href="javascript:;">
                        <slot name="options">{{options.label}}</slot>    
                    </a>
                    <a class="dropdown-item dropdown-options" v-else href="javascript:;">
                        <slot name="options">{{options}}</slot>
                    </a>
                </div>
                <div id="spinner" v-if="loading">
                    <hollow-dots-spinner 
                    :animation-duration="1000"
                    :dot-size="10"
                    :dots-num="3"
                    color="#00448b"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Mark from "mark.js";
import { HollowDotsSpinner } from 'epic-spinners';
export default {
    inject: ['$validator'],
    props:{
        validateOptions:{
            type:Array,
            default(){
                return []
            }
        },
        clearValue:{
            type:Boolean,
            default: false
        },
        options: {
            type: Array,
            default() {
                return []
            }
        },
        label: {
            type: String,
            default() {
                return ""
            }
        },
        to:{ 
            type: String,
            default() {
                return "";
            }
        },
        placeholder: {
            type: String,
            default() {
                return ""
            }
        },
        clearable: {
            type: Boolean,
            default() {
                return false
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading:Boolean,
        loadComplete:Boolean,
        lazyLoading:{
           type:Boolean,
           default: false
        },
        id:String    
    },
    data(){
        return{
            optionsVisible: false,
            search:"",
            selectedOption:""
        }
    },
    methods:{
        closeDropDown() {
            this.optionsVisible = false;
        },
        emitDropDownOpen() {
            this.optionsVisible = !this.optionsVisible;
            if(!this.optionsVisible) {
                this.search = this.selectedOption;
            }
            // if(!this.optionsVisible && this.search || this.selectedOption != this.label || this.options){
            //     this.search = "";
            //     this.selectedOption = "";
            // }
            if(this.optionsVisible) {
                this.$emit('open');
            }
        },
        selectValue(val){
            this.optionsVisible = false;
            if(this.isObjectOptions){
                this.search = val.label;
                this.selectedOption = val;
                this.$emit('blur');
                this.$emit('input', this.options[val.optionsIndex]);
            } else{
                this.search = val;
                this.selectedOption = val;
                this.$emit('blur');
                this.$emit('input',val);
            }
            // this.search = this.selectedOption;
        },
        showOptions(event){
            // this.selectedOption = $event.target.value;
            if(this.search != ""){
                this.optionsVisible = true;
            }
            else{
                this.optionsVisible = false;
            }
        },
        documentClick(e){
            if(this.optionsVisible == true){
                console.log('input enter')
                let el = this.$refs.dropdownMenu
                let target = e.target;
                if (!this.$el.contains(event.target)) {
                    if(this.isObjectOptions){
                        this.search = this.selectedOption.label;
                        this.$emit('input', this.options[this.selectedOption.optionsIndex]);
                    }
                    else{
                        this.search = this.selectedOption;
                        this.$emit('input',this.selectedOption);
                    }
                    this.optionsVisible = false;
                }
            }
        },
        clearFieldEvent(event) {
            if(event.keyCode == 8 || event.keyCode == 46) {
                if(this.clearable){
                    if(this.isObjectOptions){
                        console.log('obejctClear')
                        // this.selectedOption.length - 1;
                        // this.search.length - 1;
                        // if(this.search.length == 1){
                        //     this.search = "",
                        //     this.selectedOption = ""
                        // }
                        this.$emit('input', null);
                    }
                    else{
                        console.log('ArrClear')
                        // this.selectedOption.length - 1;
                        // this.search.length - 1;
                        // if(this.search.length == 1){
                        //     this.search = "";
                        //     this.selectedOption = "";
                        // }
                        this.$emit('input',null);
                    }    
                } else {
                    // this.selectedOption.length - 1;
                    //     this.search.length - 1;
                    // event.preventDefault();
                    return;
                }
            }
        },
        clearField() {
            if(this.clearable){
                if(this.isObjectOptions){
                    this.search = "",
                    this.selectedOption = ""
                    this.$emit('input', null);
                }
                else{
                    this.search = "",
                    this.selectedOption = ""
                    this.$emit('input',null);
                }    
            } else {
                event.preventDefault();
                return;
            }
        }
    },
    computed:{
        isObjectOptions() {
            if (this.options.length) {
                return typeof(this.options[0]) === 'object'
            }
            return false
        },
        filterOptions(){
            var self = this;
            if(this.isObjectOptions) {
                let filtered = this.options.filter(function(option){
                    return option[self.label].toLowerCase().indexOf(self.search.toLowerCase()) >= 0;
                });
                return filtered.map(function(elem, index){
                    return {
                        optionsIndex: index,
                        label: elem[self.label]
                    }
                });
            } else {
                return this.options.filter(function(option){
                    return option.toLowerCase().indexOf(self.search.toLowerCase())>=0;
                });
            }
        }
    },
    mounted(){
        if(this.label) {
            if(this.$attrs.value == null){
                this.selectedOption = "";
                this.search = "";
            } else {
                this.search = this.$attrs.value[this.label];
                this.selectedOption = this.$attrs.value[this.label];
            }
        } else {
            if(this.$attrs.value == null){
                this.selectedOption = "";
                this.search = "";
            } else {
                this.selectedOption = this.$attrs.value;
                this.search = this.$attrs.value;

            }
        }
    },
    created(){
        $(document).ready(function(){
            $(".inputDropdown").focus(function(){
                $(this).parent().addClass("optionOpacity")
            }).focusout(function(){
                $(this).parent().removeClass("optionOpacity");
            })
            $(document).keydown(function(objEvent) {        
                if (objEvent.ctrlKey) {          
                    if (objEvent.keyCode == 65) {                         

                        return false;
                    }            
                }        
            });
        });
        document.addEventListener('click', this.documentClick);
        if(this.lazyLoading) {
            document.getElementById(this.id).addEventListener(
            'scroll',() => {
                    var scrollTop = document.getElementById(this.id).scrollTop;
                    var scrollHeight = document.getElementById(this.id).scrollHeight; // added
                    var offsetHeight = document.getElementById(this.id).offsetHeight;
                    var clientHeight = document.getElementById(this.id).clientHeight;
                    var contentHeight = scrollHeight - offsetHeight; // added
                    if (contentHeight <= scrollTop) // modified
                    {
                        if(!this.loading  && !this.loadComplete) {
                            this.$emit('updateMethod', this.options.length);
                        }
                    }
                    else{
                        document.getElementById('spinner').style.display='none';
                    }
                },
                false
            );
        }        
    },
    destroyed() {
        document.removeEventListener('click', this.documentClick)
    },
    components:{
        HollowDotsSpinner
    },
    watch:{
        clearValue: {
            handler(newValue) {
                if(newValue == true) {
                    this.search = "";
                    this.selectedOption = "";
                }
            },
            deep: true
        }
    }
}
</script>
<style scoped>
.inputDropdown{
    /* -webkit-box-shadow: 2px 0 2px -2px #00448b;
    box-shadow: 2px 0 2px -2px #00448b; */
    display: block !important;
    max-width: 100% !important;
    height: 34px !important;
    padding: 7px 12px !important;
    font-size: 13px !important;
    line-height: 1.5384616 !important;
    color: #303031 !important;
    background: right;
    width: 100%;
}
input:focus + div.optionsDisplay{
    opacity: 0.2 !important;
}
.optionsDisplay{
    color: #303031 !important;
    height: 25px !important;
    margin: 5px 0px 4px 0px !important;
    padding: 14px 1px 8px 0px !important;
    float: left !important;
    line-height: 0px !important;
    font-size: 13px !important;
    align-items: center;
    /* display: block; */
    vertical-align: middle;
    position: absolute;
}
.dropdown-menu {
    min-width: 16rem;
    padding: 0rem 0;
    border-radius: 12px !important;
    max-height: 145px;
    overflow-y: auto;
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    min-width: 160px;
    padding: 5px 0;
    margin: 0;
    width: 100%;
    overflow-y: auto;
    border: 1px solid rgba(0,0,0,.26);
    box-shadow: 0 3px 6px 0 rgba(0,0,0,.15);
    border-top: none;
    border-radius: 0 0 4px 4px;
    text-align: left;
    list-style: none;
    /* background: #fff; */
}
.dropdown-item {
    line-height: 3rem !important;
    height: 2.8rem !important;
}
.dropdown-options:hover{
    background-color: #00448b !important;
    color: #fff !important;
}
.dropdown-toggle::after{
    display: none !important;
}
.toggleImage{
    position: relative;
    cursor: pointer;
    padding: 6px 6px 0px;
    height: 34px;
}
.inputDropdown{
    padding: 6px 0px !important;
}
.toggleImage i {
    color: #303031;
    font-size: 24px;
    height: 24px;
    line-height: 24px;
    width: 24px;
    display: flex;
}
.clearSelect{
    position: relative;
    cursor: pointer;
    padding: 6px 6px 0px;
    height: 34px;
}
.clearSelect i {
    color: #94bef0;
    font-size: 14px;
    height: 24px;
    line-height: 24px;
    width: 24px;
    display: flex;
    justify-content: flex-end;
}
/* .inputDropdown:hover+.clearSelect,.clearSelect:hover{
    display: block;
} */
/* .clearSelect{
    display: none;
} */
.select-placeholder::-webkit-input-placeholder{
    opacity: 0 !important;
}
.hollow-dots-spinner{
    position: relative !important;
}
.optionOpacity .optionsDisplay{
  opacity: 0.2 !important;
}
.opacityKey{
    opacity: 0 !important;
    display: none;
}
.disabled{
    background-color: #f5f6f9 !important;
    cursor: not-allowed !important;
}
.not-allowed{
    cursor: not-allowed !important;
}
.d-select{
    border-bottom: solid 1px #cdd2da;
}
::-webkit-input-placeholder{
    padding-left: 0px !important;
}
.dropdownBottom{
    user-select: none !important;
}
</style>
